<template>
  <div class="container px-0">
    <div class="restaurant">
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container-tabs">
          <template>
            <div>
              <v-row>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title pb-0">Описание</h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="nameRU" ref="nameRUTextarea"
                            :placeholder="isnameRUFocused ? '' : textareaPlaceholder
                            " @focus="isnameRUFocused = true" @blur="isnameRUFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Название EN</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="nameEN" ref="nameENTextarea"
                            :placeholder="isnameENFocused ? '' : textareaPlaceholder
                            " @focus="isnameENFocused = true" @blur="isnameENFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Номер в списке</p>
                        </v-col>
                        <v-col cols="" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="resultSortOrder"
                            ref="postSortOrderTextarea" :placeholder="isresultSortOrderFocused ? '' : textareaPlaceholder
                            " @focus="isresultSortOrderFocused = true" @blur="isresultSortOrderFocused = false"
                            @input="handleInput"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание RU</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="descriptionRU"
                            ref="descriptionRUTextarea" :placeholder="isdescriptionRUFocused ? '' : textareaPlaceholder
                            " @focus="isdescriptionRUFocused = true" @blur="isdescriptionRUFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Описание EN</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="descriptionEN"
                            ref="descriptionENTextarea" :placeholder="isdescriptionENFocused ? '' : textareaPlaceholder
                            " @focus="isdescriptionENFocused = true" @blur="isdescriptionENFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>



                    <!-- <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">ФИО KZ</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="fioKZ" ref="fioENTextarea"
                            :placeholder="isfioKZFocused ? '' : textareaPlaceholder
                              " @focus="isfioKZFocused = true" @blur="isfioKZFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div> -->

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Город RU</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="cityRU" ref="cityTextarea"
                            :placeholder="iscityRUFocused ? '' : textareaPlaceholder
                            " @focus="iscityRUFocused = true" @blur="iscityRUFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content px-0 py-0">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Город EN</p>
                        </v-col>
                        <v-col cols="5" class="col-paddings-r-l px-0 py-0">
                          <textarea class="col-description textarea-pt" v-model="cityEN" ref="cityENTextarea"
                            :placeholder="iscityENFocused ? '' : textareaPlaceholder
                            " @focus="iscityENFocused = true" @blur="iscityENFocused = false"></textarea>
                        </v-col>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px; width: auto;">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Начало</p>
                        </v-col>

                        <div class="col-paddings-r-l  px-0 py-0">
                          <input type="date" style="height: 15px; width: 110px" class="col-description"
                            v-model="dateFrom" ref="orderNumTextarea" value="dateFrom">
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>

                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px; width: auto;">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Конец</p>
                        </v-col>

                        <div class="col-paddings-r-l  px-0 py-0">
                          <input type="date" style="height: 15px; width: 110px" class="col-description" v-model="dateTo"
                            ref="orderNumTextarea" value="dateTo">
                        </div>
                      </div>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title">Фотография</h2>
                    </v-col>
                    <v-col cols="12" class="photo-description">
                      <p class="information-text-1">Фото статей</p>
                      <span class="information-text-2">638рх* 592рх</span>
                      <p class="information-text-3">1mb</p>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12">
                      <div class="img-container-flex">
                        <div v-if="uploadedImages.length > 0" class="uploaded-images-container">
                          <div v-for="(image, index) in uploadedImages" :key="index" class="uploaded-image-container">
                            <img :src="image.url" :alt="`Uploaded Image ${index + 1}`" class="uploaded-image-logo" />
                            <button @click="removeImage(index)" class="remove-image-button">
                              <img src="@/assets/img/photo-delete.svg" alt="" />
                            </button>
                          </div>
                        </div>
                        <input type="file" accept="image/*" id="img-input-1" @change="uploadImages" multiple
                          style="display: none" />
                        <div class="btn-photo">
                          <label for="img-input-1" class="custom-file-upload">
                            <img src="@/assets/img/input-logo.svg" alt="" />
                          </label>
                        </div>
                      </div>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="btn-save-container">
                    <button class="btn-save" @click="handleButtonClick">
                      Сохранить
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                    Закрыть
                  </div>
                </template>
              </v-snackbar>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.btn-save-container {
  text-align: end;
}

.btn-save {
  border-radius: 8px;
  background: var(--03, #FFD500);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.photo-description {
  display: flex;
  align-items: center;
}

.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}

.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}

.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.input-select-width {
  width: 30%;
}

/* .product-big-container {
  margin-left: 39px;
  margin-right: 30px; 
} */
.col-content-energy {
  display: flex;
  align-items: flex-start;
}

.weight-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

.month-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

::v-deep

/* eslint-disable-next-line */
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background: #f2f2f680;
}

::v-deep .v-list-item__title {
  align-self: center;

  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

::v-deep .v-application .primary--text {
  color: #bcb648 !important;
  caret-color: #bcb648 !important;
}

::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--06, #fff);
}

::v-deep .v-text-field>.v-input__control>.v-input__slot:before,
.v-text-field>.v-input__control>.v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.select-text {
  padding-left: 5px;
}

.select-container {
  margin: 0 0 0 auto;
  width: 63px;
}

.textarea-pt {
  padding-top: 19px;
}

.work-margin-bot {
  margin-bottom: 50px;
}

.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 146.667% */
}

.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}

.work-1 {
  text-align: end;
}

.dot-work {
  margin-left: 69px;
}

.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  margin-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input-2 {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal;
  /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}

.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}

.margin-logo-divider {
  margin-bottom: 48px;
}

.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-file-upload {
  cursor: pointer;
}

.img-container-flex {
  display: flex;
  justify-content: space-between;
}

.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%;
  max-height: 100%;
}

.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
  padding-top: 20px;
  position: relative;
}

.work-margin-img {
  margin-bottom: 15px;
}

.work-img {
  width: 22px;
  height: 22px;
}

.col-img {
  flex-direction: column-reverse;
  display: flex;
}

.work-margin {
  padding-bottom: 15px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}

.tel-pointer {
  cursor: pointer;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-content {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px;
  /* Минимальная высота для textarea */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}

.remove-image-button {
  /* position: absolute; */
  top: 0;
  right: 0;
  color: white;
  border: none;
  cursor: pointer;
}
</style>
<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { parse, formatISO } from 'date-fns';

export default {
  name: "resultBody",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      selectedImage: null,
      message: "",
      uploadedImageLogo: "",
      uploadedImages: [],
      currentImageIds: [],
      isPlaceholderHidden: false,
      isnameRUFocused: false,
      isnameENFocused: false,
      isdescriptionRUFocused: false,
      isdescriptionENFocused: false,
      iscityRUFocused: false,
      iscityENFocused: false,
      // isnameKZFocused: false,
      cityRU: "",
      cityEN: "",
      cityKZ: "",
      nameRU: "",
      nameEN: "",
      nameKZ: "",
      descriptionRU: "",
      descriptionEN: "",
      descriptionKZ: "",
      resultSortOrder: null,
      isresultSortOrderFocused: false,
      // nameKZ: "",
      dateFrom: "",
      dateTo: "",
      resultUpdate: [],
      snackbar: false,
      // productbase: "",

      uploadImagesProduct: "",
      currentImageId: "",
      isImageChanged: false,
    };
  },
  created() {
    this.nameRU = this.responseData.name;
    this.cityRU = this.responseData.city;
    this.resultSortOrder = this.responseData.sortOrder;
    if (this.responseData.dateFrom) this.dateFrom = this.responseData.dateFrom.split('T')[0];
    if (this.responseData.dateTo) this.dateTo = this.responseData.dateTo.split('T')[0];
    this.descriptionRU = this.responseData.description;

    if (this.responseData.localizations[0]) {
      this.descriptionEN = this.responseData.localizations[0].description
      this.nameEN = this.responseData.localizations[0].name
      this.cityEN = this.responseData.localizations[0].city

    }

    // if (this.responseData.localizations[1]) {
    //   this.descriptionKZ = this.responseData.localizations[1].description
    //   this.nameKZ = this.responseData.localizations[1].name
    //   this.cityKZ = this.responseData.localizations[1].city

    // }




    this.resultUpdate = this.responseData;
    if (this.responseData.images.length && this.responseData.images[0]) {
      const randomParam = Math.random();
      this.uploadedImageLogo =
        process.env.VUE_APP_API_HOST + this.responseData.images[0].imagePath + `?random=${randomParam}`;
      this.currentImageId = this.responseData.images[0].id;
      console.log(this.uploadedImageLogo)
    };
    if (this.responseData.images.length) {
      this.uploadedImages = this.responseData.images.map((image) => {
        const randomParam = Math.random();
        return {
          url: `${process.env.VUE_APP_API_HOST}${image.imagePath}?random=${randomParam}`,
          id: image.id,
          file: null // Пустое поле для файла, так как мы загружаем с сервера
        };
      });
      this.currentImageIds = this.responseData.images.map((image) => image.id);
      console.log(this.uploadedImages);
    };
    if (this.responseData.localizations.length && this.responseData.localizations[0]) {
      this.nameEN = this.responseData.localizations[0].name;
    }

    // if (this.responseData.localizations.length && this.responseData.localizations[1]) {
    //   this.nameKZ = this.responseData.localizations[1].name;
    // }
  },
  methods: {
    handleInput() {
      // Оставить только цифры
      this.resultSortOrder = this.resultSortOrder.replace(/\D/g, "");
    },
    editProduct() {
      console.log(this.resultUpdate);



      if (this.nameRU) {
        this.resultUpdate.name = this.nameRU;
      }
      if (this.descriptionRU) {
        this.resultUpdate.description = this.descriptionRU;
      }
      if (this.resultSortOrder) {
        this.resultUpdate.sortOrder = this.resultSortOrder;
      }

      if (!this.resultUpdate.localizations) {
        this.resultUpdate.localizations = [];
      }

      if (!this.resultUpdate.localizations[0]) {
        this.resultUpdate.localizations[0] = {};
        this.resultUpdate.localizations[0].locale = "en";
      }

      // if (!this.resultUpdate.localizations[1]) {
      //   this.resultUpdate.localizations[1] = {};
      //   this.resultUpdate.localizations[1].locale = "kz";
      // }

      if (this.nameEN) {
        this.resultUpdate.localizations[0].name = this.nameEN;
      }

      // if (this.nameKZ) {
      //   this.resultUpdate.localizations[1].name = this.nameKZ;
      // }

      if (this.descriptionEN) {
        this.resultUpdate.localizations[0].description = this.descriptionEN;
      }

      // if (this.descriptionKZ) {
      //   this.resultUpdate.localizations[1].description = this.descriptionKZ;
      // }

      if (this.city) {
        this.resultUpdate.city = this.cityRU;
      }

      if (this.cityEN) {
        this.resultUpdate.localizations[0].city = this.cityEN;
      }

      // if (this.cityKZ) {
      //   this.resultUpdate.localizations[1].city = this.cityKZ;
      // }


      if (this.dateFrom) {
        const date = parse(this.dateFrom, 'yyyy-MM-dd', new Date());
        const isoDateString = formatISO(date);
        this.resultUpdate.dateFrom = isoDateString;
      }
      if (this.dateTo) {
        const date = parse(this.dateTo, 'yyyy-MM-dd', new Date());
        const isoDateString = formatISO(date);
        this.resultUpdate.dateTo = isoDateString
      }

      if (!this.nameRU || this.nameRU.trim() === '') {
        this.snackbar = true;
        this.message = "Название обязательно для заполнения";
        return;
      }

      if (!this.dateFrom || this.dateFrom.trim() === '') {
        this.snackbar = true;
        this.message = "Дата начала обязательно для заполнения";
        return;
      }

      if (!this.dateTo || this.dateTo.trim() === '') {
        this.snackbar = true;
        this.message = "Дата окончания обязательно для заполнения";
        return;
      }

      this.$http
        .put(`/api/Results`, this.resultUpdate, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.snackbar = true;
          this.message = "Результат успешно изменен";
        })
        .catch((error) => {
          console.log(error);

          this.snackbar = true;

          if (error.response && error.response.status === 400) {
            this.message = "Заполните данные";
          } else {
            this.message = "Ошибка при сохранении результата  ";
          }
        });
    },
    selectOptionPackage(PackageType) {
      this.selectedOptionPackageType = PackageType;
      console.log(`Вы выбрали опцию: ${PackageType}`);
    },
    handleButtonClick() {
      const isImageUpdated = this.isImageChanged;

      if (isImageUpdated) {
        this.updateOrCreateImages()
          .then(() => {
            this.editProduct();
            window.location.reload(true);
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка обновления продукта с изображениями";
          });
      } else {
        this.editProduct();
      }
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1);
    },
    updateOrCreateImages() {
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        params: {
          entityName: "resultbase",
          entityId: this.$route.params.resultId,
        },
      };

      const imagesToUpload = this.uploadedImages.slice(0, 3).filter(image => image.file !== null);

      const uploadPromises = imagesToUpload.map((image) => {
        const formData = new FormData();
        formData.append("file", image.file);

        if (image.id) {
          // Если есть id, то обновляем изображение
          return this.$http.put(`/api/Image/${image.id}`, formData, config);
        } else {
          // Если id нет, то создаем новое изображение
          return this.$http.post("/api/Image", formData, config);
        }
      });

      return Promise.all(uploadPromises)
        .then((responses) => {
          this.snackbar = true;
          this.message = "Картинки успешно обновлены";
          responses.forEach((response, index) => {
            const timestamp = new Date().getTime();
            this.uploadedImages[index].url =
              process.env.VUE_APP_API_HOST + response.data.imagePath + `?t=${timestamp}`;
          });
          console.log(this.uploadedImages);

        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка обновления картинок";
          return Promise.reject(error);
        });
    },
    uploadImages(event) {
      const files = event.target.files;

      // Проверяем общее количество изображений
      if (files.length > 3) {
        this.snackbar = true;
        this.message = "Можно добавить не более 3 изображений";
        return;
      }

      this.isImageChanged = true;

      // Копируем существующие изображения
      const existingImages = [...this.uploadedImages];

      // Очищаем массив для новых изображений, если будет замена
      this.uploadedImages = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImages.push({
            url: e.target.result,
            file: file,
            id: existingImages[i] ? existingImages[i].id : null // Сохраняем ID существующих изображений, если они есть
          });

          // Если оставшиеся изображения не заменяются, добавляем их обратно
          if (i === files.length - 1) {
            for (let j = files.length; j < existingImages.length; j++) {
              if (this.uploadedImages.length < 3) {
                this.uploadedImages.push(existingImages[j]);
              }
            }
          }
        };
        reader.readAsDataURL(file);
      }

      console.log("This result images", this.uploadedImages);
    },
    uploadImageLogo(event) {
      let file = event.target.files[0];

      // Проверяем, изменилось ли изображение
      if (file !== this.selectedImage) {
        this.isImageChanged = true;
        this.selectedImage = file;

        if (file) {
          this.uploadedImageLogo = URL.createObjectURL(file);
          console.log(this.uploadedImageLogo);
          this.uploadImagesProduct = file;
        } else {
          this.uploadedImageLogo = null;
          this.uploadImagesProduct = null;
        }
      }
    },
    createNewProductImage() {
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        params: {
          entityName: "resultbase",
          entityId: this.$route.params.resultId,
        },
      };
      const imagesToUpload = this.uploadedImages.slice(0, 3);
      const uploadPromises = imagesToUpload.map((image) => {
        const formData = new FormData();
        formData.append("file", image.file);
        return this.$http.post("/api/Image", formData, config);
      });

      Promise.all(uploadPromises)
        .then(() => {
          this.snackbar = true;
          this.message = "Картинка успешно добавлена";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка добавления картинки";
        });
    },
    updateProductImage() {
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        params: {
          entityName: "resultbase",
          entityId: this.$route.params.resultId,
        },
      };
      const imagesToUpload = this.uploadedImages.filter(image => image.file !== null).slice(0, 3);
      const updatePromises = imagesToUpload.map((image) => {
        console.log(this.uploadedImages + "sda");
        console.log(this.uploadedImages + "ID" + image.id + "ID");
        const formData = new FormData();
        formData.append("file", image.file);
        return this.$http.put(`/api/Image/${image.id}`, formData, config);
      });

      Promise.all(updatePromises)
        .then((responses) => {
          this.snackbar = true;
          this.message = "Картинки успешно изменена";
          responses.forEach((response, index) => {
            const timestamp = new Date().getTime();
            this.uploadedImages[index].url =
              process.env.VUE_APP_API_HOST + response.data.imagePath + `?t=${timestamp}`;
          });
          console.log(this.uploadedImages);
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка изменения картинки";
        });
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },


    pluralizeMonths(months) {
      const num = parseInt(months);
      if (isNaN(num)) return "";

      if (num === 1) return "месяц";
      if (num > 1 && num < 5) return "месяца";
      return "месяцев";
    },

    selectOption(option) {
      this.selectedOption = option;
      console.log(`Вы выбрали опцию: ${option}`);
    },
  },
  watch: {
    descriptionRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionRUTextarea");
      });
    },
    descriptionEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionENTextarea");
      });
    },
    nameRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameRUTextarea");
      });
    },
    nameEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameENTextarea");
      });
    },
    cityEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("cityENTextarea");
      });
    },
    cityRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("cityTextarea");
      });
    },
  },


  computed: {
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },
};
</script>
